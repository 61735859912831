.leaflet-control-select .leaflet-control-button {
  cursor: pointer;
  font-size: 20px; }

.leaflet-control-select .leaflet-control-select-menu {
  width: auto; }

.leaflet-control-select .leaflet-control-select-menu:nth-of-type(1) {
  position: absolute;
  top: -3px;
  left: -2px; }

.leaflet-control-select .leaflet-control-select-menu:nth-of-type(n + 2) {
  margin-top: -7px;
  position: absolute;
  left: 100%;
  margin-left: 5px; }

.leaflet-control-select .leaflet-control-select-menu {
  background-color: white;
  color: black; }
  .leaflet-control-select .leaflet-control-select-menu .leaflet-control-select-menu-line {
    padding: 7px;
    padding-right: 20px;
    padding-left: 10px;
    margin: 0;
    cursor: pointer;
    white-space: nowrap;
    display: -webkit-box;
    width: auto; }
    .leaflet-control-select .leaflet-control-select-menu .leaflet-control-select-menu-line.group.group-opened {
      background-color: grey;
      color: white; }
    .leaflet-control-select .leaflet-control-select-menu .leaflet-control-select-menu-line .leaflet-control-select-menu-line-content {
      font-size: 1rem;
      width: 100%; }
      .leaflet-control-select .leaflet-control-select-menu .leaflet-control-select-menu-line .leaflet-control-select-menu-line-content .icon {
        position: absolute;
        right: 6px;
        margin-top: 1px;
        width: 10px;
        text-align: center; }
      .leaflet-control-select .leaflet-control-select-menu .leaflet-control-select-menu-line .leaflet-control-select-menu-line-content .icon,
      .leaflet-control-select .leaflet-control-select-menu .leaflet-control-select-menu-line .leaflet-control-select-menu-line-content .text {
        display: inline; }
    .leaflet-control-select .leaflet-control-select-menu .leaflet-control-select-menu-line span {
      display: inline; }
