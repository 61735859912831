@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Nag+Mundari:wght@700&family=Noto+Serif+JP&display=swap');

.logo_n{
    font-family: 'Bebas Neue', sans-serif;
    color: green;
}

.logo_n2{
    font-family: 'Noto Sans Nag Mundari', sans-serif;
    font-family: 'Noto Serif JP', serif;
}

.MDBContainer .MDBNavbar{
    height: 1%;
    margin-top: 0%;
    margin-bottom: 0%;
}

.mt-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

.mb-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

.pt-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

.pb-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

